import { useEffect } from 'react'
import Head from 'next/head'
import { useIntl } from 'intl'
import { analytics } from 'helpers'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import messages from './messages'
import s from './404.module.scss'


const Page404: React.FC = () => {
  const { formatMessage } = useIntl()

  useEffect(() => {
    analytics.statistics.sendEvent('404 page')
  }, [])

  return (
    <>
      <Head>
        <title>{formatMessage(messages.title)}</title>
      </Head>
      <Flex className={s.container} justify="center">
        <div>
          <div className={s.title}>
            404
          </div>
          <Text
            message={messages.text}
            color="default"
            align="center"
            size={40}
          />
          <Box mt={24}>
            <Text
              message={messages.note}
              color="default"
              align="center"
              size={24}
            />
          </Box>
        </div>
      </Flex>
    </>
  )
}


export default Page404
