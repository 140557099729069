export default {
  title: {
    en: '404 | StakeWise',
    ru: '404 | StakeWise',
    fr: '404 | StakeWise',
    es: '404 | StakeWise',
    pt: '404 | StakeWise',
    de: '404 | StakeWise',
    zh: '404 | StakeWise',
  },
  text: {
    en: 'Page not found',
    ru: 'Страница не найдена',
    fr: 'Page introuvable',
    es: 'Página no encontrada',
    pt: 'Página não encontrada',
    de: 'Seite nicht gefunden',
    zh: '找不到頁面',
  },
  note: {
    en: 'Ooooups! Looks like you got lost.',
    ru: 'Ууууупс! Похоже, вы заблудились.',
    fr: 'Ooooups! Il semblerait que vous soyez perdus.',
    es: '¡Ups! Parece que nos hemos perdido.',
    pt: 'Ooooups! Parece que você se perdeu.',
    de: 'Ooooups! Du hast Dich wohl verirrt',
    zh: '哦噢! 看起來你迷失方向了。',
  },
}
